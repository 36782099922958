<template>
  <div></div>
</template>
<script setup>
try {
  const params = new URLSearchParams(window.location.search)
  if (params.get('debug') === '1') {
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://cdn.jsdelivr.net/npm/eruda';
    scriptElement.type = 'text/javascript';
    scriptElement.onload = () => {
      window.eruda && window.eruda.init();
    }

    document.head.appendChild(scriptElement);
  }
} catch (ex) {
  console.log(ex);
}
</script>

